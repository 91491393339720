<script lang="ts" setup>
  import { useShared } from '@plenny/visitor';

  const shared = useShared();
</script>
<template>
  <footer class="footer">
    <div class="footer__top container">
      <div class="footer__block footer__block--contact">
        <SfLogo class="footer__logo" />
        <div class="footer__contact-wrapper" v-if="shared.department">
          <a :href="shared.department.maps || '#'" class="footer__address-link footer__contact-item">
            <IconGeoAlt />
            <div class="footer__address">
              <span v-if="shared.department.first_line" v-text="shared.department.first_line" />
              <span v-if="shared.department.second_line" v-text="shared.department.second_line" />
              <span v-if="shared.department.third_line" v-text="shared.department.third_line" />
            </div>
          </a>
          <a v-if="shared.department.email" :href="`mailto:${shared.department.email}`" class="footer__phone-link footer__contact-item">
            <IconEnvelope />
            <span v-html="shared.department.email" />
          </a>
          <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="footer__phone-link footer__contact-item">
            <IconPhone />
            <span v-html="shared.department.phone" />
          </a>
        </div>
      </div>
      <div v-for="group in shared.menu.menu_bottom" class="footer__block">
        <span class="footer__block-title">{{ group.name }}</span>
        <ul class="footer__list">
          <li v-for="item in group.children">
            <FooterNavItem :item="item" class="link" />
          </li>
        </ul>
      </div>
    </div>
    <Separator />
    <div class="container footer__bottom">
      <div>
        <CopyrightMessage />
      </div>
      <div>
        <p>
          {{ $t('Realizacja') + ': ' }}<a href="https://promoznawcy.pl/" target="_blank">PROMOznawcy.pl</a>
        </p>
      </div>
    </div>
    <FixedActions />
  </footer>
</template>
<style lang="scss" scoped>
  .footer {
    padding-top: 65px;
    margin-top: 65px;
    color: var(--color-main-black);
    background-color: var(--color-primary-white);
    box-shadow: var(--box-shadow-primary);

    a {
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);

      &:hover {
        color: var(--color-primary-blue);
      }
    }

    &__logo {
      height: 60px;
      max-width: 175px;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4rem;
    }

    &__contact-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      svg {
        font-size: 1.25rem;
      }
    }

    &__contact-item {
      display: flex;
      gap: 1rem;
      align-items: flex-start;
    }

    &__address {
      display: flex;
      flex-direction: column;
    }

    &__block {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      gap: 2.5rem;
      padding: 0 2.5rem;

      &:not(:last-child) {
        border-right: 1px solid var(--color-primary-light-gray);
      }

      &:first-child {
        padding-left: 0;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
      list-style: none;
    }

    &__block-title {
      font-size: 1.5rem;
      font-weight: var(--font-weight-700);
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      font-size: 0.75rem;
      padding: 1rem 0;
    }
  }

  @media (max-width: 1200px) {
    .footer {
      &__top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
      }

      &__block {
        &:not(:last-child) {
          border-right: none;
        }

        &:first-child {
          padding-left: 2.5rem;
        }
      }

      &__bottom {
        flex-direction: column;
        align-items: center;
        font-size: 0.75rem;
        gap: 0.625rem;
      }
    }
  }

  @media (max-width: 992px) {
    .footer {
      padding-top: 2.5rem;
      margin-top: 1.5rem;

      &__logo {
        height: 40px;
        max-width: 125px;
      }

      &__top {
        grid-template-columns: 1fr;
        gap: 2.5rem;
        padding: 0 0.625rem 60px;
      }

      &__block {
        padding: 0;
        gap: 1.25rem;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
</style>

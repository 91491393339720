<script setup lang="ts">
  import type { PropType } from 'vue';
  import type { Breadcrumb, Section } from '$types';
  import { useOpeningHours } from '$storefront';

  type Module = {
    title: string | null,
    description_short: string | null,
  }

  defineProps({
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: true },
    module: { type: Object as PropType<Module>, required: true },
    sections: { type: Array as PropType<Section[]>, required: true },
    departments: { type: Array, required: true },
    main: { type: Object, required: true },
  });

  const formatHours = useOpeningHours();
</script>
<template>
  <main class="container">
    <div class="contact-wrapper">
      <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
      <SectionRenderer :sections="sections" placement="before" />

      <div class="contact">
        <div class="contact__details">
          <header>
            <SegmentHeading larger type="h1">
              {{ module.title }}
            </SegmentHeading>
            <div v-if="module.description_short" v-html="module.description_short" />
          </header>
          <div class="contact__main">
            <a :href="main.maps || '#'" :title="$t('Zobacz na mapach')" class="contact__main__address" rel="noreferrer nofollow noopener" target="_blank">
              <IconGeoAlt class="contact__main__address__icon" />
              <span class="contact__main__address__lines">
                <span v-if="main.name" v-text="main.name" />
                <span v-if="main.first_line" v-text="main.first_line" />
                <span v-if="main.second_line" v-text="main.second_line" />
                <span v-if="main.third_line" v-text="main.third_line" />
              </span>
            </a>
            <a v-if="main.phone" :href="`tel:${main.phone}`" class="contact__main__phone">
              <IconPhone class="contact__main__phone__icon" />
              <span v-html="main.phone" />
            </a>
            <a v-if="main.email" :href="`mailto:${main.email}`" class="contact__main__email">
              <IconEnvelope class="contact__main__email__icon" />
              <span v-html="main.email" />
            </a>
          </div>
          <section class="contact__main__opening">
            <header>
              <SegmentHeading type="h2">
                {{ $t('Godziny otwarcia') + ':' }}
              </SegmentHeading>
            </header>
            <div class="contact__main__opening__container">
              <template v-for="entry in formatHours(main.hours)">
                <div class="contact__main__hours">
                  <span><b>{{ entry.days }}</b></span>
                  <span>{{ entry.hours }}</span>
                </div>
              </template>
            </div>
          </section>
        </div>
        <section class="contact__form">
          <header>
            <h2 class="contact__form__header">
              {{ $t('Masz pytania? Skorzystaj z naszego formularza kontaktowego.') }}
            </h2>
          </header>
          <div class="contact__form__container">
            <SfContactForm :departments="departments" />
          </div>
        </section>
      </div>

      <Segment class="contact__map" :title="$t('Znajdź nas na mapie')">
        <VisitorLink href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x473d83519ad028dd:0xb63f29fc47feb1cc?sa=X&ved=1t:8290&ictx=111" target="_blank" class="contact__map-link" :aria-label="$t('Przejdź do map Google')">
          <img src="../../../assets/images/wodkan-map.png" :alt="$t('Mapa')" class="contact__map-image">
          <span class="contact__map-text">
            {{ $t('Przejdź do map Google') }}
          </span>
        </VisitorLink>
      </Segment>

      <Segment :title="$t('Nasze sklepy')" id="Nasze-sklepy">
        <div class="contact__departments">
          <div v-for="department in departments" class="contact-department">
            <div class="contact-department__place">
              <a :href="department.maps || '#'" :title="$t('Zobacz na mapach')" class="contact-department__address" rel="noreferrer nofollow noopener" target="_blank">
                <span class="contact-department__address-title" v-if="department.name" v-text="department.name" />
                <span v-if="department.first_line" v-text="department.first_line" />
                <span v-if="department.second_line" v-text="department.second_line" />
                <span v-if="department.third_line" v-text="department.third_line" />
              </a>
            </div>
            <div class="contact-department__contact">
              <a v-if="department.phone" :href="`tel:${department.phone}`" class="contact-department__phone">
                {{ $t('tel.') + ':' }}
                <span v-html="department.phone" />
              </a>
              <a v-if="department.internal_phone" :href="`tel:${department.internal_phone}`" class="contact-department__internal_phone">
                {{ $t('wew.') + ':' }}
                <span v-html="department.internal_phone" />
              </a>
              <a v-if="department.mobile" :href="`tel:${department.mobile}`" class="contact-department__mobile">
                {{ $t('tel. kom.') + ':' }}
                <span v-html="department.mobile" />
              </a>
              <a v-if="department.fax" :href="`tel:${department.fax}`" class="contact-department__fax">
                {{ $t('fax') + ':' }}
                <span v-html="department.fax" />
              </a>
              <a v-if="department.email" :href="`mailto:${department.email}`" class="contact-department__email">
                {{ $t('e-mail') + ':' }}
                <span v-html="department.email" />
              </a>
            </div>
            <div class="contact-department__opening">
              <div>{{ $t('Godziny otwarcia') + ':' }}</div>
              <template v-for="entry in formatHours(department.hours)">
                <div class="contact-department__opening__hours">
                  <span><b>{{ entry.days }}</b></span>
                  <span>{{ entry.hours }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </Segment>

      <SectionRenderer :sections="sections" placement="after" />
    </div>
  </main>
</template>
<style lang="scss" scoped>
  .contact-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .contact {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    &__details {
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      width: 50%;
      gap: 2rem;
    }

    &__main {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__address, &__phone, &__email {
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        line-height: 1.375rem;
        gap: 0.75rem;

        &__icon {
          font-size: 1.375rem;
          line-height: 1.375rem;
        }
      }

      &__address {
        &__lines {
          display: flex;
          flex-direction: column;
        }
      }

      &__opening {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      &__hours {
        font-weight: normal;
        line-height: 1.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 1rem;

        b {
          font-size: 0.875rem;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      width: 50%;

      &__header {
        padding: 1rem 2.5rem;
        font-size: 1rem;
      }

      &__container {
        background: var(--color-white);
        padding: 2.5rem;
      }
    }

    &__map-image {
      width: 100%;
      aspect-ratio: 1400/551;
    }

    &__map-text {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      align-content: center;
      text-align: center;
      font-size: 1.5rem;
      background-color: rgba(0, 0, 0, .15);
      font-weight: var(--font-weight-600);
      opacity: 0;
      transition-property: opacity;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
    }

    &__map :deep(.segment__content) {
      position: relative;

      &:hover .contact__map-text {
        opacity: 1;
      }
    }

    &__departments {
      counter-reset: department;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 1.5rem;

      .contact-department {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background: var(--color-white);
        padding: 1.5rem;

        &__address-title{
          font-weight: var(--font-weight-700);
        }

        &__place {
          &::before {
            display: inline;
            font-size: 2.5rem;
            font-weight: var(--font-weight-400);
            line-height: 2.5rem;
            margin-left: 0.5rem;
            float: right;
            counter-increment: department;
            content: counter(department);
            color: black;
          }
        }

        &__address {
          display: flex;
          flex-direction: column;
        }

        &__phone, &__internal_phone, &__mobile, &__fax, &__email {
          display: block;
        }

        &__opening {
          display: flex;
          flex-direction: column;

          &__hours {
            font-weight: normal;
            line-height: 1.5rem;
            display: grid;
            grid-template-columns: 1fr 100px;
            font-size: 1rem;

            b {
              font-size: 0.875rem;
            }
          }
        }
      }
    }

  }


  @media(max-width: 1400px) {
    .contact {
      &__departments {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media(max-width: 1200px) {
    .contact {
      &__departments {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media (max-width: 992px) {

    .contact {
      flex-direction: column;
      padding: 0 0.625rem;

      &__form {
        width: 100%;

        &__header {
          padding: 0 0 0.625rem;
        }

        &__container {
          padding: 1.5rem 0.625rem;
        }
      }

      &__details {
        width: 100%;
        padding-bottom: 2rem;
      }

      &__departments {
        grid-template-columns: 1fr;
        gap: 1rem;

        .contact-department {
          padding: 1rem;
        }
      }

      &__map-text {
        font-size: 1rem;
        opacity: 1;
      }
    }
  }
</style>

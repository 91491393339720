<script setup>
  import { ref } from 'vue';
  import { route, useShared } from '@plenny/visitor';
  import { useCustomerOrderAbility, useCustomerAccepted } from '$storefront';

  const props = defineProps({
    product: { type: Object, required: true },
    brand: { type: Object, required: true },
  });

  const variant = defineModel('variant', { type: Object, required: false });
  const shared = useShared();
  const successModalVisible = ref(false);
  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();

  const showSuccessModal = () => {
    successModalVisible.value = true;
  };
</script>
<template>
  <div class="product-actions">
    <div class="product-actions__info">
      <div v-if="canCustomerOrder" class="product-actions__price">
        <SfProductPrice :price="variant || product" presentation />
        <div class="product-actions__omnibus">
          <SfProductOmnibus :price="variant || product" />
          <SfProductUnitPrice :price="variant || product" />
        </div>
      </div>
      <template v-else>
        <VisitorLink transparent small v-if="isCustomerAccepted === undefined" :href="route('web.account.session.create')" class="product-actions__sign-in" rel="nofollow">
          {{ $t('Zaloguj się aby zobaczyć ceny') + '.' }}
        </VisitorLink>
        <span v-else class="product-actions__inactive-account">
          {{ $t('Twoje konto nie jest jeszcze aktywne') + '.' }}
        </span>
      </template>
      <SfProductStock :variant="variant" />
      <div class="product-actions__data">
        <div v-if="brand" class="product-actions__data-row">
          <span class="product-actions__data-property">{{ $t('Marka') + ':' }}</span>
          <VisitorLink :href="route('web.product.index', { brands: [brand.id] })" class="product-actions__data-value">
            {{ brand.name }}
          </VisitorLink>
        </div>
      </div>
    </div>
    <div class="product-actions__picker">
      <div class="product-actions__variant-picker-wrapper">
        <SfVariantPicker v-model:variant="variant" :product class="product-actions__variant-picker" />
      </div>
      <template v-if="canCustomerOrder">
        <!-- CartStoreButton emits 'added' event so we can catch in and based on it open/close modal-->
        <SfCartStoreButton :variant @added="showSuccessModal" />
        <CartButtonSuccessModal :open="successModalVisible" :product :variant @update:open="successModalVisible = $event" />
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .product-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;

    &__info {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
    }

    &__price {
      --sf-product-price-presentation-current-font-line-height: 1;
    }

    &__omnibus {
      margin-top: 0.5rem;
    }

    &__data {
      display: flex;
      flex-direction: column;
      row-gap: 0.625rem;
    }

    &__data-row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.375rem;
      line-height: 1;
      font-size: 1rem;
    }

    &__data-value {
      font-weight: var(--font-weight-600);
    }

    a.product-actions__data-value {
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
      cursor: pointer;

      &:hover {
        color: var(--color-primary-blue);
      }
    }

    &__picker {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      :deep(.cart-store-button__button) {
        flex-direction: column;
      }
    }

    &__variant-picker {
      margin-top: 1rem;
    }

    &__inactive-account,
    &__sign-in {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      padding: 1rem 0;
    }

    &__sign-in {
      color: var(--color-primary-blue);
      font-weight: var(--font-weight-500);
      text-decoration: underline;
      transition: color 0.2s ease;

      @media(hover: hover) {
        &:hover {
          color: var(--color-main-black);
        }
      }
    }
  }
</style>

<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import { trans } from '@plenny/translator';

  type Contact = {
    type: 'phone' | 'internal' | 'mobile' | 'fax' | 'email',
    value: string,
  }

  type Item = {
    name: string,
    icon: string | null,
    contacts: Contact[],
  }

  const props = defineProps({
    header: String,
    header_type: String,
    content: String,
    photos: Array,
    items: { type: Array as PropType<Item[]>, required: true },
  });

  const types = computed(() => {
    return {
      phone: trans('tel.'),
      internal: trans('tel. wew.'),
      mobile: trans('tel. kom.'),
      fax: trans('fax'),
      email: trans('e-mail'),
    };
  });

  function makeContactHref(contact: Contact) {
    switch (contact.type) {
      case 'phone':
      case 'mobile':
      case 'fax':
        return `tel:${contact.value}`;
      case 'email':
        return `mailto:${contact.value}`;
      default:
        return 'javascript:void(0)';
    }
  }
</script>
<template>
  <Segment class="cards" :title="header" :headerType="header_type">
    <div v-if="content" class="cards__description" v-html="content" />
    <div class="cards__container">
      <div v-for="item in items" class="card">
        <div class="card__name">
          <b v-if="item.name">{{ item.name }}</b>
          <span v-if="item.icon" class="card__icon" aria-hidden="true" v-html="item.icon" />
        </div>
        <div v-if="item.contacts" class="card__contacts">
          <div v-for="contact in item.contacts" class="contact">
            <a :href="makeContactHref(contact)">
              {{ types[contact.type] + ':' }} {{ contact.value }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </Segment>
</template>
<style scoped lang="scss">
  .cards {

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1.5rem;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    background: var(--color-white);
    border-radius: 5px;

    &__contacts {
      display: flex;
      flex-direction: column;
    }

    &__name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 1rem;
    }

    &__icon {
      display: flex;
      flex: 0 0 2rem;

      :deep(svg) {
        width: 2rem;
        height: 2rem;
      }
    }
  }


  @media(max-width: 1400px) {
    .cards {
      &__container {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media(max-width: 1200px) {
    .cards {
      &__container {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media(max-width: 992px) {
    .cards {
      &__container {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      .card {
        padding: 1rem;
      }
    }
  }
</style>
